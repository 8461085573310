/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const brand = process.env.REACT_APP_BRAND

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      fr: {
        page: require(`./resources/fr-${brand}/page.json`),
        browserDeprecated: require(`./resources/fr-${brand}/browserDeprecated.json`),
        validation: require(`./resources/fr-${brand}/validations.json`),
        form: require(`./resources/fr-${brand}/form.json`),
        productPage: require(`./resources/fr-${brand}/productPage.json`),
        personalDetails: require(`./resources/fr-${brand}/personalDetails.json`),
        contact: require(`./resources/fr-${brand}/contact`),
        whoAreWe: require(`./resources/fr-${brand}/whoAreWe`),
        termsAndConditions: require(`./resources/fr-${brand}/termsAndConditions`),
        legalTerms: require(`./resources/fr-${brand}/legalTerms`),
        FAQ: require(`./resources/fr-${brand}/FAQ`),
        error: require(`./resources/fr-${brand}/error`),
        privacyPolicy: require(`./resources/fr-${brand}/privacyPolicy`),
        landingPage: require(`./resources/fr-${brand}/landingPage.json`),
        landingPageDealer: require(`./resources/fr-${brand}/landingPageDealer.json`),
        cookies: require(`./resources/fr-${brand}/cookies.json`),
        payment: require(`./resources/fr-${brand}/payment.json`),
        confirmation: require(`./resources/fr-${brand}/confirmation.json`),
        tanAuthentification: require(`./resources/fr-${brand}/tanAuthentification.json`),
        mailDispatch: require(`./resources/fr-${brand}/mailDispatch.json`),
        journeyCompleted: require(`./resources/fr-${brand}/journeyCompleted.json`),
        linkExpiry: require(`./resources/fr-${brand}/linkExpiry.json`),
        vehicleSearch: require(`./resources/fr-${brand}/vehicleSearch.json`),
        customerHappinessIndex: require(`./resources/fr-${brand}/customerHappinessIndex.json`),
        eSign: require(`./resources/fr-${brand}/eSign.json`),
        searchBar: require(`./resources/fr-${brand}/searchBar.json`),
      },
    },
    lng: 'fr',

    keySeparator: '.', // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
