export const SET_STEP_DATA = 'form//SET_STEP_DATA'
export const SET_FORM_DATA = 'form//SET_FORM_DATA'
export const RESET_FORM_DATA = 'form//RESET_FORM_DATA'

export function setStepData(step, data) {
  return {
    type: SET_STEP_DATA,
    payload: {
      step,
      data,
    },
  }
}

export function setFormData(data) {
  return {
    type: SET_FORM_DATA,
    payload: data,
  }
}

export function resetFormData(transactionId) {
  return {
    type: RESET_FORM_DATA,
    payload: transactionId,
  }
}

export function getStepData(store, step) {
  return store.form.stepData[step]
}

export function getFormData(store) {
  return store.form
}

export function getSaveAndContinueActivated(store) {
  return store.form?.stepData?.[2]?.dataPrivacyConsentAcceptanceSaveAndContinue
}

const initialState = {
  transactionId: '',
  transactionStatus: '',
  stepData: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FORM_DATA:
      return {
        ...state,
        ...action.payload,
      }

    case RESET_FORM_DATA:
      return {
        ...initialState,
        transactionId: action.payload,
      }

    case SET_STEP_DATA:
      return {
        ...state,
        stepData: {
          ...state.stepData,
          [action.payload.step]: action.payload.data,
        },
      }
    default:
      return state
  }
}
