const fileNameCGV = 'CGV-EN-LIGNE-CONTRAT-ENTRETIEN-VN-VO.pdf'
const filenameCGU = 'CGU.pdf'
const filenameUsedMaintenance = 'Notice_Entretien_VO_STDA.pdf'

const brandToFilenameMaintenancePlus = {
  audi: 'ServiceOnline_CG EntretienPU_VN2403_Audi.pdf',
  cupra: 'ServiceOnline_CG EntretienPU_VN2403_CUPRA.pdf',
  seat: 'ServiceOnline_CG EntretienPU_VN2403_SEAT.pdf',
  skoda: 'ServiceOnline_CG EntretienPU_VN2403_SKODA.pdf',
  vw: 'ServiceOnline_CG EntretienPU_VN2403_VW.pdf',
}

const brandToFilenameMaintenance = {
  audi: 'ServiceOnline_CG Entretien_VN2403_Audi.pdf',
  cupra: 'ServiceOnline_CG Entretien_VN2403_CUPRA.pdf',
  seat: 'ServiceOnline_CG Entretien_VN2403_SEAT.pdf',
  skoda: 'ServiceOnline_CG Entretien_VN2403_SKODA.pdf',
  vw: 'ServiceOnline_CG Entretien_VN2403_VW.pdf',
}

export {
  fileNameCGV,
  filenameCGU,
  filenameUsedMaintenance,
  brandToFilenameMaintenance,
  brandToFilenameMaintenancePlus,
}
